import { Component } from "react";
// import React from "react";
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import './../Footer/footer.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faMap,
  faAddressBook,
  faListUl,
  faList,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, Route, Routes } from "react-router-dom";

const Footer = () => {

  const userJson = localStorage.getItem('userJson');

  // Parse the userJson if it exists
  const user = userJson ? JSON.parse(userJson) : null;

  // Access attributes from the user object
  
  const tenant_name = user?.tenant_name ;
  const [isMenuActive, setMenuActive] = useState(true);
  const handleMenuToggle = () => { setMenuActive(!isMenuActive); };
  const primaryColor = user?.colors_of_theme?.header_color;
  const jsonColor = {
    background: primaryColor,
  };

  const userDashboard = user ? user.user_auth_urls : null;


  return (
    <div className="navigation footer">
      <div className={`menuToggle ${isMenuActive ? 'active' : ''}`} onClick={handleMenuToggle} style={jsonColor}> <i></i> </div>
      <div className="menus">
        <ul>
          {/* <li style={{ '--i': '0.1s' }}><a href="#"><FontAwesomeIcon icon={faMap} /></a></li>
                    <li style={{ '--i': '0.2s' }}><a href="#"><FontAwesomeIcon icon={faAddressBook} /></a></li>
                    <li></li>
                    <li style={{ '--i': '0.2s' }}><a href="#"><FontAwesomeIcon icon={faListUl} /></a></li>
                    <li style={{ '--i': '0.1s' }}><a href="#"><FontAwesomeIcon icon={faHome} /></a></li> */}
          <li style={{ "--i": "0.1s" }}>
            <NavLink
              to={`/landing/tenant/${tenant_name}/map-search`}
              
              style={{ color: jsonColor }}
            >
              <FontAwesomeIcon icon={faMap} />
            </NavLink>
          </li>
          <li style={{ "--i": "0.2s" }}>
            <NavLink
              to={`https://${userDashboard}/#/login`}
              target="_blank"
             
              style={{ color: jsonColor}}
            >
              <FontAwesomeIcon icon={faAddressBook} />
            </NavLink>
          </li>
          <li></li>
          <li style={{ "--i": "0.2s" }}>
            <NavLink
              to={`/landing/tenant/${tenant_name}/offer-listing`}
              
              style={{ color: jsonColor}}
            >
              <FontAwesomeIcon icon={faListUl} />
            </NavLink>
          </li>
          <li style={{ "--i": "0.1s" }}>
            <NavLink
              to={`/landing/tenant/${tenant_name}/home`}
             
              style={{ color: jsonColor}}
            >
              <FontAwesomeIcon icon={faHome} />
            </NavLink>
          </li>
        </ul>
      </div>
    </div>);

};

export default Footer;
