import axios from 'axios';
import React, { useEffect, useState, useCallback, useRef } from "react";



const userJson = localStorage.getItem('userJson');
  const user = userJson ? JSON.parse(userJson) : null;
  const tenant_name = user?.tenant_name ;
  const api_key = user?.api_key?.tenant_api_key;
  
  var requiredHeaders = {
    'Content-Type': 'application/json',
    // Authorization: `Bearer ${this.jwtToken}`,
    tenant: tenant_name || '',
    // user: user_alias || '',
    apikey: api_key || '',
    company: 'shopprop',
  };

  const headersForPropertySearch = () => {
    return {
      tenant: tenant_name,
    };
  };


export const getUserAliasIdFromEmail = async (email) => {
  try {
    const url = `https://5abidcl07h.execute-api.us-east-1.amazonaws.com/beta/Iam/tenant/${tenant_name}/Alias/user_email/${email}`;
    const response = await axios.get(url, {
        headers: requiredHeaders,
      });
    return response.data;
  } catch (error) {
    console.error('Error fetching user id:', error);
    throw error;
  }
};

export const postPriorityApi = async (payload) => {
    try {
    const postUrl = `https://96qiwu1nc8.execute-api.us-east-1.amazonaws.com/beta/neuronService/priority`;
      const postResponse = await axios.post(postUrl, payload, {
        headers: requiredHeaders,
      })
      return postResponse.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  };



  export const privateDataByCoordinates = async (payload,tenant_name) => {
    try {
      const postUrl = `https://mfdx9kmod7.execute-api.us-east-1.amazonaws.com/private_listing_service/prod/private/tenant/${tenant_name}/viewport`;
      const postResponse = await axios.post(postUrl,payload ,{
        headers: headersForPropertySearch(),
        });
      return  postResponse.data;
    } catch (error) {
      console.error('Error fetching user id:', error);
      throw error;
    }
  };

  export const publicDataByCoordinates = async (payload,tenant_name) => {
    try {
      const postUrl = `https://mz5wkrw9e4.execute-api.us-east-1.amazonaws.com/property_listing_service/prod/public/tenant/${tenant_name}/viewport`;
      const postResponse = await axios.post(postUrl,payload ,{
        headers: headersForPropertySearch(),
        });
      return  postResponse.data;
    } catch (error) {
      console.error('Error fetching user id:', error);
      throw error;
    }
  };

  export const privateDataByCityState = async (payload,city,state,tenant_name) => {
    try {
      const postUrl = `https://mfdx9kmod7.execute-api.us-east-1.amazonaws.com/private_listing_service/prod/private/tenant/${tenant_name}/city/${city}/state/${state}`;
      const postResponse = await axios.post(postUrl,payload ,{
        headers: headersForPropertySearch(),
        });
      return  postResponse.data;
    } catch (error) {
      console.error('Error fetching user id:', error);
      throw error;
    }
  };

  export const publicDataByCityState = async (payload,city,state,tenant_name) => {
    try {
      const postUrl = `https://mz5wkrw9e4.execute-api.us-east-1.amazonaws.com/property_listing_service/prod/public/tenant/${tenant_name}/city/${city}/state/${state}`;
      const postResponse = await axios.post(postUrl,payload ,{
        headers: headersForPropertySearch(),
        });
      return  postResponse.data;
    } catch (error) {
      console.error('Error fetching user id:', error);
      throw error;
    }
  };


  export const privateDataByZipcode = async (payload,zipcode,tenant_name) => {
    try {
      const postUrl = `https://mfdx9kmod7.execute-api.us-east-1.amazonaws.com/private_listing_service/prod/private/tenant/${tenant_name}/zipcode/${zipcode}`;
      const postResponse = await axios.post(postUrl,payload ,{
        headers: headersForPropertySearch(),
        });
      return  postResponse.data;
    } catch (error) {
      console.error('Error fetching user id:', error);
      throw error;
    }
  };

  export const publicDataByZipcode = async (payload,zipcode,tenant_name) => {
    try {
      const postUrl = `https://mz5wkrw9e4.execute-api.us-east-1.amazonaws.com/property_listing_service/prod/public/tenant/${tenant_name}/zipcode/${zipcode}`;
      const postResponse = await axios.post(postUrl,payload ,{
        headers: headersForPropertySearch(),
        });
      return  postResponse.data;
    } catch (error) {
      console.error('Error fetching user id:', error);
      throw error;
    }
  };

  export const privateDataByAddress = async (payload,address,tenant_name) => {
    try {
      const postUrl = `https://mfdx9kmod7.execute-api.us-east-1.amazonaws.com/private_listing_service/prod/private/tenant/${tenant_name}/address/${address}`;
      const postResponse = await axios.post(postUrl,payload ,{
        headers: headersForPropertySearch(),
        });
      return  postResponse.data;
    } catch (error) {
      console.error('Error fetching user id:', error);
      throw error;
    }
  };

  export const publicDataByAddress = async (payload,address,tenant_name) => {
    try {
      const postUrl = `https://mz5wkrw9e4.execute-api.us-east-1.amazonaws.com/property_listing_service/prod/public/tenant/${tenant_name}/address/${address}`;
      const postResponse = await axios.post(postUrl,payload ,{
        headers: headersForPropertySearch(),
        });
      return  postResponse.data;
    } catch (error) {
      console.error('Error fetching user id:', error);
      throw error;
    }
  };


  export const getPropertyByMLS = async (mls,descriptor) => {
    try {
      const url = `https://5iyxc1pky7.execute-api.us-east-1.amazonaws.com/search_service/prod/search_by_gsi/index_name/mls_property_id_index/partition_key/${mls}/sort_key/${descriptor}`;
      const response = await axios.get(url);
      return response.data;
    }catch (error) {
      console.error('Error fetching user id:', error);
      throw error;
    }
  };