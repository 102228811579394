import { Component } from "react";
// import React from "react";
import React, { useState, useEffect } from 'react';
import './../Listing/Listing.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightToBracket,
  faEnvelope,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import Routing from '../../components/routing';
import Footer from '../../components/Footer/footer';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import office from '../../assets/office.png'; // Update the path if necessary
// import "bootstrap/dist/css/bootstrap.min.css"
import LoginPopup from '../Login/login';
import { Amplify, Auth } from "aws-amplify";

const Listing = () => {

  const userJson = localStorage.getItem('userJson');

  // Parse the userJson if it exists
  const user = userJson ? JSON.parse(userJson) : null;

  // Access attributes from the user object
  const logo = user ? user.logo : null;
  const text = user ? user.home_slider[0].title : null;
  const contactNo = user ? user.contact.phone1 : null;
  const email = user ? user.contact.email1 : null;
  const agentDashboard = user ? user.agent_auth_urls : null;
  const userDashboard = user ? user.user_auth_urls : null;
  const primaryColor = user?.colors_of_theme?.header_color;
  const jsonColor = {
    background: primaryColor,
  };

  const [isToggleChecked, setIsToggleChecked] = useState(false);

 


  const urlToOpenAgent = `https://${agentDashboard}`;
  function openNewBrowserAgent(url) {
    setToggleStateToFalse();
    window.open(url, '_system');
  }
  const urlToOpenIUser = `https://${userDashboard}`;
  function openNewBrowserUser(url) {
    setToggleStateToFalse();
    window.open(url, '_system');
  }

 
  // Function to toggle the checkbox and update the variable
 

  // Function to set the toggle state explicitly to false in LoginPopup component
  const setToggleStateToFalse = () => {
    setIsToggleChecked(false);
  };

  const handleCheckboxChange = (event) => {
    setIsToggleChecked(event.target.checked);
  };

  return (
    <div className="body2">
      <div id="card">
        <div className="rect" style={jsonColor}>
        </div>

        <div className="section top-section" style={jsonColor}>

          <span > <img src={logo} className=" img-style  w rounded-circle " alt="logo" />
          </span>
          <h1 className="white-text">{text}</h1>
        </div>
        <div className="section bottom-section">
          <div className="menu-wrap">
            <input type="checkbox" className="toggler" checked={isToggleChecked}
              onChange={handleCheckboxChange}
            />
            <div className="hamburger"><div></div></div>
            <div className="menu">
              <div>
                <div>
                  <ul>
                    <li> <LoginPopup isToggleChecked={isToggleChecked} setToggleStateToFalse={setToggleStateToFalse} /></li>
                    {/* <li><a onClick={() => openNewBrowserAgent(urlToOpenAgent)} rel="noopener noreferrer">Agent Dashboard </a></li> */}
                    <li><a onClick={() => openNewBrowserUser(urlToOpenIUser)}>User dashboard</a></li>

                    <li><a href="#" target="_blank">Private Listing</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-sm-12 row">
            <div className="width-section1">
              <img src={office} alt="Example" className="img-style2" />
            </div>
            <div className="width-section2">
              <div className="button-section text-center">
                <button target="_blank" className="btn demo-btn-new-top text-center button-1" style={jsonColor}>
                <a onClick={() => openNewBrowserUser(urlToOpenIUser)}>
                    <div className="icon-class icon-color"><FontAwesomeIcon icon={faRightToBracket} />
                    </div>
                  </a>
                </button>


                <button className="btn demo-btn-new-top text-center mx-2 button-1" style={jsonColor}>
                  <a href='https://tawk.to/chat/64077df931ebfa0fe7f138d9/1gquk85qm' >
                  <div className="icon-class icon-color"><FontAwesomeIcon icon={faAndroid} />
                  </div>
                  </a>
                </button>

                <button className="btn demo-btn-new-top text-center button-1" style={jsonColor}>
                  <a href={'mailto:' + email} target="_blank"><div className="icon-class icon-color"><FontAwesomeIcon icon={faEnvelope} />
                  </div></a>
                </button>


                <button target="_blank" className="btn demo-btn-new-top text-center mx-2 button-1" style={jsonColor}>
                  <a href={'tel:' + contactNo} target="_blank">
                    <div className="icon-class icon-color"><FontAwesomeIcon icon={faPhone} /> </div>
                  </a>
                </button>

              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
};
export default Listing;
