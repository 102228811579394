import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import "./map.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlacesAutocomplete from "react-places-autocomplete";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from "@react-google-maps/api";
import markerIcon from "../../assets/marker-icon.png";
import ErrorImg from "../../assets/error-1.png";
import NoImage from "../../assets/No-image-available.jpeg";
import {
  faList,
  faMapMarkerAlt,
  faFilter,
  faBookmark,
  faArrowDownWideShort,
  faMap,
  faPlus,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../Footer/footer";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "react-datepicker/dist/react-datepicker.css";
import apiModule from "../Api/apiModule";
import Loader from "../Loader/loader";

const MapComponent = () => {
  const mapRef = useRef(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 47.6768927,
    lng: -122.2059833,
  });
  const [zoom, setZoom] = useState(15.5);
  var [markers, setMarkers] = useState([]);
  const [searchAddress, setSearchAddress] = useState("");
  const navigate = useNavigate();
  const [showMap, setShowMap] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(faList);
  const [properties, setProperties] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showSavedFilters, setShowSavedFilters] = useState(false);
  const [isFilterVisible, setFilterVisibility] = useState(false);
  const [isFilterAreaVisible, setFilterAreaVisibility] = useState(false);
  const [isFilterBedVisible, setFilterBedVisibility] = useState(false);
  const [isFilterPropertyTypeVisible, setFilterPropertyTypeVisibility] =
    useState(false);
  const [isMoreFilterVisible, setMoreFilterVisibility] = useState(false);
  const [propertyTypeValues, setPropertyTypeValues] = useState([]);
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [areaMinValue, setAreaMinValue] = useState("");
  const [areaMaxValue, setAreaMaxValue] = useState("");
  const [minLotArea, setminLotArea] = useState("");
  const [maxLotArea, setmaxLotArea] = useState("");
  const [BathFilter, setBathFilter] = useState("");
  const [bedFilter, setBedFilter] = useState("");
  const [maxHOA, setMaxHOA] = useState("");
  const [minYearBuilt, setminYearBuilt] = useState("");
  const [minStories, setminStories] = useState("");
  const [minParking, setminParking] = useState("");
  const [maxDays, setmaxDays] = useState("");
  const [minDays, setminDays] = useState("");
  const [minCost, setminCost] = useState("");
  const [propertyStatus, setpropertyStatus] = useState("");
  const [listingBy, setlistingBy] = useState("");
  const [maxCost, setmaxCost] = useState("");
  const [excludeShortSales, setExcludeShortSales] = useState(false);
  const [fixerUpper, setFixerUpper] = useState(false);
  const [openHouses, setOpenHouses] = useState(false);
  const [priceReduction, setPriceReduction] = useState(false);
  const [isGarage, setisGarage] = useState(false);
  const [isAirCondition, setisAirCondition] = useState(false);
  const [isBasement, setisBasement] = useState(false);
  const [isHeating, setisHeating] = useState(false);
  const [isParking, setisParking] = useState(false);
  const [isSwimming, setisSwimming] = useState(false);
  const [greenHomes, setgreenHomes] = useState(false);
  const [isLaundry, setisLaundry] = useState(false);

  var [toggleChecked, setToggleChecked] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mapLoaded, setMapLoaded] = useState(false);

  let exclusiveProperty = false;

  const path = window.location.href;
  const tenantParam = path.split("/tenant").pop();
  const tenantName = tenantParam.split("/")[1];

  const storedUserJson = localStorage.getItem("userJson");
  let user = storedUserJson ? JSON.parse(storedUserJson) : null;
  let logo = user ? user.logo : null;
  let tenant_name = user ? user.tenant_name : tenantName;

  const [queryParams] = useSearchParams();

  const handleToggle = () => {
    const path = window.location.href;

    const lastSegment = path.split("/").pop();
    const type = lastSegment.split("?")[0];

    const searchedAddress = queryParams.get("searched_address_format");
    const zipcodeRegex = /\d{5}/;

    const queryParamsString = queryParams.toString(); // Convert queryParams to string
    const queryParamsArray = queryParamsString.split("&"); // Split the string by '&'

    let lat;
    let lng;
    for (let i = 0; i < queryParamsArray.length; i++) {
      const param = queryParamsArray[i];
      const [key, value] = param.split("=");

      if (key === "lat") {
        lat = parseFloat(value);
      } else if (key === "lng") {
        lng = parseFloat(value);
      }

      var mapCenterForViewport = {
        lat: lat,
        lng: lng,
      };
    }

    if (!toggleChecked) {
      if (type == "zipcode") {
        const zipcode = searchedAddress.match(zipcodeRegex)?.[0];
        const queryParams = `?zipcode=${zipcode}&searched_address_format=${searchedAddress}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/zipcode${queryParams}&isExclusiveProperty=${!toggleChecked}`;
        navigate(routeLink);
      } else if (type == "citystate") {
        const addressComponents = searchedAddress
          .split(",")
          .map((item) => item.trim());
        const city = addressComponents[0];
        const state = addressComponents[1];
        const country = addressComponents[2];
        const queryParams = `?city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/citystate${queryParams}&isExclusiveProperty=${!toggleChecked}`;
        navigate(routeLink);
      } else if (type == "address") {
        const addressComponents = searchedAddress
          .split(",")
          .map((item) => item.trim());
        const address = addressComponents[0];
        const [city, state, country] = addressComponents.slice(-3);
        const queryParams = `?address=${address}&city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/address${queryParams}&isExclusiveProperty=${!toggleChecked}`;
        navigate(routeLink);
      } else if (type == "viewport") {
        console.log(mapCenterForViewport);
        const queryParams = `?lat=${mapCenterForViewport.lat}&lng=${mapCenterForViewport.lng}&zoom=${zoom}`;
        console.log(queryParams);
        const routeLink = `/landing/tenant/${tenant_name}/map-search/viewport${queryParams}&isExclusiveProperty=${!toggleChecked}`;
        navigate(routeLink);
      } else {
        console.log(mapCenter);
        const queryParams = `?lat=${mapCenter.lat}&lng=${mapCenter.lng}&zoom=${zoom}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search${queryParams}&isExclusiveProperty=${!toggleChecked}`;
        console.log(routeLink);
        navigate(routeLink);
      }
      window.location.reload();
    } else {
      if (type == "zipcode") {
        const zipcode = searchedAddress.match(zipcodeRegex)?.[0];
        const queryParams = `?zipcode=${zipcode}&searched_address_format=${searchedAddress}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/zipcode${queryParams}`;
        navigate(routeLink);
      } else if (type == "citystate") {
        const addressComponents = searchedAddress
          .split(",")
          .map((item) => item.trim());
        const city = addressComponents[0];
        const state = addressComponents[1];
        const country = addressComponents[2];
        const queryParams = `?city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/citystate${queryParams}`;
        navigate(routeLink);
      } else if (type == "address") {
        const addressComponents = searchedAddress
          .split(",")
          .map((item) => item.trim());
        const address = addressComponents[0];
        const [city, state, country] = addressComponents.slice(-3);
        const queryParams = `?address=${address}&city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/address${queryParams}`;
        navigate(routeLink);
      } else if (type == "viewport") {
        console.log(mapCenterForViewport);
        const queryParams = `?lat=${mapCenterForViewport.lat}&lng=${mapCenterForViewport.lng}&zoom=${zoom}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/viewport${queryParams}`;
        navigate(routeLink);
      } else {
        const queryParams = `?lat=${mapCenter.lat}&lng=${mapCenter.lng}&zoom=${zoom}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search${queryParams}`;
        navigate(routeLink);
      }
    }
    setToggleChecked(!toggleChecked);
  };

  const getHomesForSale = useCallback(() => {
    const path = window.location.href;
    const queryParamsString = queryParams.toString(); // Convert queryParams to string
    const queryParamsArray = queryParamsString.split("&"); // Split the string by '&'
    console.log(queryParamsArray);
    const queryParamsObject = {};
    let lat;
    let lng;
    for (let i = 0; i < queryParamsArray.length; i++) {
      const param = queryParamsArray[i];
      const [key, value] = param.split("=");
      if (key == "isExclusiveProperty") {
        exclusiveProperty = value;
      }

      if (key === "lat") {
        lat = parseFloat(value);
      } else if (key === "lng") {
        lng = parseFloat(value);
      }

      var mapCenterForViewport = {
        lat: lat,
        lng: lng,
      };

      const paramsToExclude = [
        "lat",
        "lng",
        "zoom",
        "isExclusiveProperty",
        "city",
        "country",
        "state",
        "searched_address_format",
        "zipcode",
        "address",
      ]; // Add the names of the parameters you want to exclude

      const filteredParamsArray = queryParamsArray.filter((param) => {
        const paramName = param.split("=")[0]; // Get the parameter name before the '=' sign
        return !paramsToExclude.includes(paramName);
      });

     

      for (let i = 0; i < filteredParamsArray.length; i++) {
        const param = filteredParamsArray[i];
        const [filterkey, filtervalue] = param.split("=");

        const decodedValue = decodeURIComponent(filtervalue);

        if (decodedValue.includes(",")) {
          queryParamsObject[filterkey] = decodedValue.split(",");
        } else {
          queryParamsObject[filterkey] = decodedValue;
        }
      }
    }

    const lastSegment = path.split("/").pop();
    const type = lastSegment.split("?")[0];

    const searchedAddress = queryParams.get("searched_address_format");
  
    if (!exclusiveProperty) {
      setToggleChecked(false);
    } else {
      toggleChecked = exclusiveProperty;

      setToggleChecked(true);
    }

    if (type == "zipcode") {
      getHomesForSaleByZipcode(searchedAddress, queryParamsObject);
    } else if (type == "citystate") {
      getHomesForSaleByCitystate(searchedAddress, queryParamsObject);
    } else if (type == "address") {
      getHomesForSaleByAddress(searchedAddress, queryParamsObject);
    } else if (type == "viewport") {
      console.log(mapCenterForViewport);
      getHomesForSaleByViewport(mapCenterForViewport, queryParamsObject);
    } else if (queryParamsArray[0] == "lat=47.6768927") {
      getHomesForSaleByViewport(mapCenter, queryParamsObject);
    } else {
      // Default behavior when there are no search parameters
      getHomesForSaleByViewport(mapCenter, queryParamsObject);
      const queryParams = `?lat=${mapCenter.lat}&lng=${mapCenter.lng}&zoom=${zoom}`;
      navigate(`/landing/tenant/${tenant_name}/map-search${queryParams}`);
    }
  }, [navigate, mapCenter, zoom, tenant_name, toggleChecked]);

  useEffect(() => {
    getHomesForSale();
  }, [toggleChecked]);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  const handleButtonClick = () => {
    setShowMap(!showMap);
    if (!showMap) {
      // Reload the page only when the faMap icon is clicked
      window.location.reload();
    }
    setButtonIcon(showMap ? faMap : faList);
    // Set the new icon based on showMap state
  };

  const handleMapMove = async () => {
    setMapLoaded(true);
    if (mapLoaded) {
      const map = mapRef.current;
      // Update the mapCenter state with the current map center
      const mapCenter = map.getCenter();

      const mapCenterForViewport = {
        lat: mapCenter.lat(),
        lng: mapCenter.lng(),
      };

      console.log(mapCenterForViewport);

      try {
        await getHomesForSaleByViewport(mapCenterForViewport);
      } catch (error) {
        console.error("Error fetching homes data:", error);
      }

      const queryParams = `?lat=${mapCenter.lat()}&lng=${mapCenter.lng()}&zoom=${zoom}`;
      navigate(
        `/landing/tenant/${tenant_name}/map-search/viewport${queryParams}`
      );

      window.location.reload();
    }
  };

  const headersForPropertySearch = useCallback(() => {
    return {
      tenant: tenant_name,
    };
  }, [tenant_name]);

  const calculateRectangleCoordinates = (centerLat, centerLng, radius) => {
    const centerLatRad = centerLat * (Math.PI / 180);
    const centerLngRad = centerLng * (Math.PI / 180);
    const radiusOfEarth = 6371000;
    const radiusDeg = radius / (radiusOfEarth * (Math.PI / 180));

    const topLeftLatRad = centerLatRad + radiusDeg;
    const topLeftLngRad = centerLngRad - radiusDeg;
    const bottomRightLatRad = centerLatRad - radiusDeg;
    const bottomRightLngRad = centerLngRad + radiusDeg;

    const topLeftLat = topLeftLatRad * (180 / Math.PI);
    const topLeftLng = topLeftLngRad * (180 / Math.PI);
    const bottomRightLat = bottomRightLatRad * (180 / Math.PI);
    const bottomRightLng = bottomRightLngRad * (180 / Math.PI);

    return {
      topLeft: { lat: topLeftLat, lon: topLeftLng },
      bottomRight: { lat: bottomRightLat, lon: bottomRightLng },
    };
  };

  const getDataByViewport = useCallback(
    async (searchedAddress, filterpayload) => {
      setIsLoading(true);
      console.log(searchedAddress);
      const centerLat = searchedAddress.lat;
      const centerLng = searchedAddress.lng;
      const radius = 15; // Specify the desired radius in meters

      const { topLeft, bottomRight } = calculateRectangleCoordinates(
        centerLat,
        centerLng,
        radius
      );

      const Payload = {
        searched_address_formatted: searchedAddress,
        property_status: "SALE",
        min_price: 25000,
        size: 200,
        allowed_mls: [
          "ARMLS",
          "ACTRISMLS",
          "BAREISMLS",
          "CRMLS",
          "CENTRALMLS",
          "MLSLISTINGS",
          "NWMLS",
          "NTREISMLS",
          "shopprop",
        ],
        cursor: null,
        location_bounds: {
          top_left: {
            lat: topLeft.lat,
            lon: topLeft.lon,
          },
          bottom_right: {
            lat: bottomRight.lat,
            lon: bottomRight.lon,
          },
        },
      };

      const payload = {
        ...Payload,
        ...filterpayload,
      };

      try {
        if (toggleChecked) {
          const api = await apiModule;

          const response = await api.privateDataByCoordinates(
            payload,
            tenant_name
          );

          setIsLoading(false);
          return response.data;
        } else {
          const api = await apiModule;

          const response = await api.publicDataByCoordinates(
            payload,
            tenant_name
          );

          setIsLoading(false);
          return response.data;
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        throw error;
      }
    },
    [mapCenter, toggleChecked]
  );

  const getHomesForSaleByViewport = useCallback(
    async (coordinates, filterPayload) => {
      try {
        const searchData = await getDataByViewport(coordinates, filterPayload);

        // Create marker data using the lat and lon values from each item in the data array
        const markerData = searchData.map((item) => ({
          lat: item.location.lat,
          lng: item.location.lon,
          address: item.address,
          imageUrl: item.image_url?.[0] || "",
          area: item.area?.finished || "",
          price: item.price?.current || "",
          bedrooms: item.bedroom?.count || 0,
          bathrooms: item.bathroom?.count || 0,
          hoa: item.hoa?.per_month || "N/A",
          propertyType: item.property_type?.value || "N/A",
          property_descriptor: item.property_descriptor?.id,
          property_descriptor_mls: item.property_descriptor?.mls_name,
        }));

        if (markerData.length > 0) {
          setMarkers(markerData);

          setZoom(13.5);
        } else {
          markers = []; // Set markers to an empty array
          setMarkers(markers);
          setZoom(zoom); // Set a default zoom level
        }

        const propertyData = searchData.map((item) => ({
          imageUrl: item.image_url?.[0] || "", // Optional chaining used here
          address: item.address || "",
          area: item.area?.finished || "",
          price: item.price?.current || "",
          bedrooms: item.bedroom?.count || 0,
          bathrooms: item.bathroom?.count || 0,
          property_descriptor: item.property_descriptor?.id,
          property_descriptor_mls: item.property_descriptor?.mls_name,
          hoa: item.hoa?.per_month || "N/A",
          propertyType: item.property_type?.value || "N/A",
        }));

        setProperties(propertyData);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [getDataByViewport, toggleChecked]
  );

  const getDataByCityState = useCallback(
    async (searchedAddress, filterpayload) => {
      setIsLoading(true);

      const addressComponents = searchedAddress
        .split(",")
        .map((item) => item.trim());

      const city = addressComponents[0].toLowerCase();
      const state = addressComponents[1].toLowerCase();

      const Payload = {
        sort_by: "last_updated_time",
        order_by: "desc",
        searched_address_formatted: searchedAddress,
        min_price: 25000,
        output: [
          "area",
          "price",
          "bedroom",
          "bathroom",
          "image_url",
          "property_descriptor",
          "location",
          "address",
          "status",
        ],
        size: 200,
        allowed_mls: [
          "ARMLS",
          "ACTRISMLS",
          "BAREISMLS",
          "CRMLS",
          "CENTRALMLS",
          "MLSLISTINGS",
          "NWMLS",
          "NTREISMLS",
          "shopprop",
        ],
      };

      const payload = {
        ...Payload,
        ...filterpayload,
      };

      try {
        if (toggleChecked) {
          const api = await apiModule;

          const response = await api.privateDataByCityState(
            payload,
            city,
            state,
            tenant_name
          );

          setIsLoading(false);
          return response.data;
        } else {
          const api = await apiModule;

          const response = await api.publicDataByCityState(
            payload,
            city,
            state,
            tenant_name
          );
          setIsLoading(false);
          return response.data;
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        throw error;
      }
    },
    [headersForPropertySearch, toggleChecked]
  );

  const getHomesForSaleByCitystate = useCallback(
    async (searchedAddress, filterPayload) => {
      try {
        const searchData = await getDataByCityState(
          searchedAddress,
          filterPayload
        );

        // Create marker data using the lat and lon values from each item in the data array
        const markerData = searchData.map((item) => ({
          lat: item.location.lat,
          lng: item.location.lon,
          address: item.address,
          imageUrl: item.image_url?.[0] || "",
          area: item.area?.finished || "",
          price: item.price?.current || "",
          bedrooms: item.bedroom?.count || 0,
          bathrooms: item.bathroom?.count || 0,
          hoa: item.hoa?.per_month || "N/A",
          propertyType: item.property_type?.value || "N/A",
          property_descriptor: item.property_descriptor?.id,
          property_descriptor_mls: item.property_descriptor?.mls_name,
        }));

        if (markerData.length > 0) {
          setMapCenter({
            lat: markerData[0].lat,
            lng: markerData[0].lng,
          });
          setZoom(13.5);
        } else {
          setMapCenter({ lat: 47.68587, lng: -122.16647 });
        }

        setMarkers(markerData);

        const propertyData = searchData.map((item) => ({
          imageUrl: item.image_url?.[0] || "", // Optional chaining used here
          address: item.address || "",
          area: item.area?.finished || "",
          price: item.price?.current || "",
          bedrooms: item.bedroom?.count || 0,
          bathrooms: item.bathroom?.count || 0,
          property_descriptor: item.property_descriptor?.id,
          property_descriptor_mls: item.property_descriptor?.mls_name,
          hoa: item.hoa?.per_month || "N/A",
          propertyType: item.property_type?.value || "N/A",
        }));

        setProperties(propertyData);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [getDataByCityState]
  );

  const getDataByZipcode = useCallback(
    async (searchedAddress, filterpayload) => {
      setIsLoading(true);
      const zipcodeRegex = /\d{5}/;
      const zipcode = searchedAddress.match(zipcodeRegex)?.[0];
      const Payload = {
        sort_by: "last_updated_time",
        order_by: "desc",
        searched_address_formatted: searchedAddress,
        property_status: "SALE",
        min_price: 25000,
        output: [
          "area",
          "price",
          "bedroom",
          "bathroom",
          "image_url",
          "property_descriptor",
          "location",
          "address",
          "status",
        ],
        size: 200,
        allowed_mls: [
          "ARMLS",
          "ACTRISMLS",
          "BAREISMLS",
          "CRMLS",
          "CENTRALMLS",
          "MLSLISTINGS",
          "NWMLS",
          "NTREISMLS",
          "shopprop",
        ],
      };
      const payload = {
        ...Payload,
        ...filterpayload,
      };

      try {
        if (toggleChecked) {
          const api = await apiModule;

          const response = await api.privateDataByZipcode(
            payload,
            zipcode,
            tenant_name
          );

          setIsLoading(false);
          return response.data;
        } else {
          const api = await apiModule;

          const response = await api.publicDataByZipcode(
            payload,
            zipcode,
            tenant_name
          );
          setIsLoading(false);
          return response.data;
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        throw error;
      }
    },
    [headersForPropertySearch, toggleChecked]
  );

  const getHomesForSaleByZipcode = useCallback(
    async (searchedAddress, filterPayload) => {
      try {
        const searchData = await getDataByZipcode(
          searchedAddress,
          filterPayload
        );

        // Create marker data using the lat and lon values from each item in the data array
        const markerData = searchData.map((item) => ({
          lat: item.location.lat,
          lng: item.location.lon,
          address: item.address,
          imageUrl: item.image_url?.[0] || "",
          area: item.area?.finished || "",
          price: item.price?.current || "",
          bedrooms: item.bedroom?.count || 0,
          bathrooms: item.bathroom?.count || 0,
          hoa: item.hoa?.per_month || "N/A",
          propertyType: item.property_type?.value || "N/A",
          property_descriptor: item.property_descriptor?.id,
          property_descriptor_mls: item.property_descriptor?.mls_name,
        }));

        if (markerData.length > 0) {
          setMapCenter({
            lat: markerData[0].lat,
            lng: markerData[0].lng,
          });
          setZoom(13.5);
        } else {
          setMapCenter({ lat: 47.68587, lng: -122.16647 });
        }

        setMarkers(markerData);

        const propertyData = searchData.map((item) => ({
          imageUrl: item.image_url?.[0] || "", // Optional chaining used here
          address: item.address || "",
          area: item.area?.finished || "",
          price: item.price?.current || "",
          bedrooms: item.bedroom?.count || 0,
          bathrooms: item.bathroom?.count || 0,
          property_descriptor: item.property_descriptor?.id,
          property_descriptor_mls: item.property_descriptor?.mls_name,
          hoa: item.hoa?.per_month || "N/A",
          propertyType: item.property_type?.value || "N/A",
        }));
        setProperties(propertyData);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [getDataByZipcode]
  );

  const getDataByAddress = useCallback(
    async (searchedAddress, filterpayload) => {
      setIsLoading(true);
      const addressComponents = searchedAddress
        .split(",")
        .map((item) => item.trim());
      const address = addressComponents[0];
      const [city, state, country] = addressComponents.slice(-3);

      const Payload = {
        sort_by: "last_updated_time",
        order_by: "desc",
        searched_address_formatted: searchedAddress,
        output: [
          "area",
          "price",
          "bedroom",
          "bathroom",
          "image_url",
          "property_descriptor",
          "location",
          "address",
          "status",
        ],
        city: city,
        state: state,
        size: 200,
        allowed_mls: [
          "ARMLS",
          "ACTRISMLS",
          "BAREISMLS",
          "CRMLS",
          "CENTRALMLS",
          "MLSLISTINGS",
          "NWMLS",
          "NTREISMLS",
          "shopprop",
        ],
      };
      const payload = {
        ...Payload,
        ...filterpayload,
      };

      try {
        if (toggleChecked) {
          const api = await apiModule;

          const response = await api.privateDataByAddress(
            payload,
            address,
            tenant_name
          );

          setIsLoading(false);
          return response.data;
        } else {
          const api = await apiModule;

          const response = await api.publicDataByAddress(
            payload,
            address,
            tenant_name
          );
          setIsLoading(false);
          return response.data;
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        throw error;
      }
    },
    [headersForPropertySearch, toggleChecked]
  );

  const getHomesForSaleByAddress = useCallback(
    async (address, filterPayload) => {
      try {
        const searchData = await getDataByAddress(address, filterPayload);

        // Create marker data using the lat and lon values from each item in the data array
        const markerData = searchData.map((item) => ({
          lat: item.location.lat,
          lng: item.location.lon,
          address: item.address,
          imageUrl: item.image_url?.[0] || "",
          area: item.area?.finished || "",
          price: item.price?.current || "",
          bedrooms: item.bedroom?.count || 0,
          bathrooms: item.bathroom?.count || 0,
          hoa: item.hoa?.per_month || "N/A",
          propertyType: item.property_type?.value || "N/A",
          property_descriptor: item.property_descriptor?.id,
          property_descriptor_mls: item.property_descriptor?.mls_name,
        }));

        if (markerData.length > 0) {
          setMapCenter({
            lat: markerData[0].lat,
            lng: markerData[0].lng,
          });
          setZoom(11.5);
        } else {
          setMapCenter({ lat: 47.68587, lng: -122.16647 });
        }

        setMarkers(markerData);

        const propertyData = searchData.map((item) => ({
          imageUrl: item.image_url?.[0] || "", // Optional chaining used here
          address: item.address || "",
          area: item.area?.finished || "",
          price: item.price?.current || "",
          bedrooms: item.bedroom?.count || 0,
          bathrooms: item.bathroom?.count || 0,
          property_descriptor: item.property_descriptor?.id,
          property_descriptor_mls: item.property_descriptor?.mls_name,
          hoa: item.hoa?.per_month || "N/A",
          propertyType: item.property_type?.value || "N/A",
        }));
        setProperties(propertyData);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [getDataByAddress]
  );

  const search = (searchedAddress) => {
    const addressComponents = searchedAddress
      .split(",")
      .map((item) => item.trim());
    const zipcodeRegex = /\d{5}/;

    if (
      addressComponents.length === 3 &&
      !zipcodeRegex.test(addressComponents.join(""))
    ) {
      const city = addressComponents[0];
      const state = addressComponents[1];
      const country = addressComponents[2];
      const queryParams = `?city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
      const routeLink = `/landing/tenant/${tenant_name}/map-search/citystate${queryParams}`;
      navigate(routeLink);
      getHomesForSaleByCitystate(searchedAddress);
      window.location.reload();
    } else if (
      addressComponents.length >= 4 &&
      !zipcodeRegex.test(addressComponents.join(""))
    ) {
      const address = addressComponents[0];
      const [city, state, country] = addressComponents.slice(-3);
      const queryParams = `?address=${address}&city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
      const routeLink = `/landing/tenant/${tenant_name}/map-search/address${queryParams}`;
      navigate(routeLink);
      getHomesForSaleByAddress(searchedAddress);
      window.location.reload();
    } else {
      const zipcodeRegex = /\d{5}/;
      const zipcode = searchedAddress.match(zipcodeRegex)?.[0];
      if (zipcode) {
        const queryParams = `?zipcode=${zipcode}&searched_address_format=${searchedAddress}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/zipcode${queryParams}`;
        navigate(routeLink);

        getHomesForSaleByZipcode(searchedAddress);
        window.location.reload();
      } else {
        getHomesForSaleByViewport(mapCenter);
      }
    }

    setSearchAddress("");
  };

  const sortOrderLimits = [
    { value: "value1", label: "Lowest Price" },
    { value: "value2", label: "Highest Price" },
    { value: "value3", label: "Less Area" },
    { value: "value4", label: "More Area" },
    { value: "value5", label: "Less Bedrooms" },
    { value: "value6", label: "More Bedrooms" },
    // Add more options as needed
  ];

  const handleClick = () => {
    setShowOptions(!showOptions);
  };

  const handleFilterClick = () => {
    setShowFilters(!showFilters);
  };

  if (sortBy === "value1") {
    properties.sort((a, b) => a.price - b.price);
  } else if (sortBy === "value2") {
    properties.sort((a, b) => b.price - a.price);
  } else if (sortBy === "value3") {
    properties.sort((a, b) => a.area - b.area);
  } else if (sortBy === "value4") {
    properties.sort((a, b) => b.area - a.area);
  } else if (sortBy === "value5") {
    properties.sort((a, b) => (a.bedrooms || 0) - (b.bedrooms || 0));
  } else if (sortBy === "value6") {
    properties.sort((a, b) => (b.bedrooms || 0) - (a.bedrooms || 0));
  }

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleApplyFilters = () => {
    let filterPayload = {};
    var filterForm = {
      min_price: minValue,
      max_price: maxValue,
      min_bathroom: BathFilter,
      min_bedroom: bedFilter,
      max_constructed_finished_sqft_size: areaMaxValue,
      min_constructed_finished_sqft_size: areaMinValue,
      property_type: propertyTypeValues,
      max_hoa_cost_per_month: maxHOA,
      min_year_built: minYearBuilt,
      min_parking_count: minParking,
      min_stories_count: minStories,
      min_lot_size_in_sqft: minLotArea,
      max_lot_size_in_sqft: maxLotArea,
      max_days: maxDays,
      min_days: minDays,
      min_cost_per_sqft: minCost,
      max_cost_per_sqft: maxCost,
      listed_by: listingBy,
      property_status: propertyStatus,
      exclude_short_sales: excludeShortSales,
      fixer_upper: fixerUpper,
      open_houses: openHouses,
      price_reduction: priceReduction,
      closed_garage: isGarage,
      has_ac: isAirCondition,
      has_basement: isBasement,
      has_central_heating: isHeating,
      has_parking_space: isParking,
      has_pool: isSwimming,
      is_green_home: greenHomes,
      is_in_unit_laundry: isLaundry,
    };

    for (let key in filterForm) {
      if (filterForm[key] && filterForm[key].length > 0) {
        filterPayload[key] = filterForm[key];
      }
    }

    if (excludeShortSales) {
      filterPayload.exclude_short_sales = excludeShortSales;
    }

    if (fixerUpper) {
      filterPayload.fixer_upper = fixerUpper;
    }

    if (openHouses) {
      filterPayload.open_houses = openHouses;
    }

    if (priceReduction) {
      filterPayload.price_reduction = priceReduction;
    }

    if (isGarage) {
      filterPayload.closed_garage = isGarage;
    }

    if (isAirCondition) {
      filterPayload.has_ac = isAirCondition;
    }

    if (isBasement) {
      filterPayload.has_basement = isBasement;
    }

    if (isHeating) {
      filterPayload.has_central_heating = isHeating;
    }

    if (isParking) {
      filterPayload.has_parking_space = isParking;
    }

    if (isSwimming) {
      filterPayload.has_pool = isSwimming;
    }

    if (greenHomes) {
      filterPayload.is_green_home = greenHomes;
    }

    if (isLaundry) {
      filterPayload.is_in_unit_laundry = isLaundry;
    }

    const path = window.location.href;

    const lastSegment = path.split("/").pop();
    const type = lastSegment.split("?")[0];

    const searchedAddress = queryParams.get("searched_address_format");
    const zipcodeRegex = /\d{5}/;
    const queryParamsString = queryParams.toString(); // Convert queryParams to string
    const queryParamsArray = queryParamsString.split("&"); // Split the string by '&'

    let lat;
    let lng;
    for (let i = 0; i < queryParamsArray.length; i++) {
      const param = queryParamsArray[i];
      const [key, value] = param.split("=");

      if (key === "lat") {
        lat = parseFloat(value);
      } else if (key === "lng") {
        lng = parseFloat(value);
      }

      var mapCenterForViewport = {
        lat: lat,
        lng: lng,
      };
    }

    if (toggleChecked) {
      if (type == "zipcode") {
        const zipcode = searchedAddress.match(zipcodeRegex)?.[0];
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const queryParams = `?zipcode=${zipcode}&searched_address_format=${searchedAddress}`;
        const toggleStatus = `&isExclusiveProperty=${toggleChecked}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/zipcode${queryParams}${toggleStatus}${queryString}`;
        navigate(routeLink);
        window.location.reload();
      } else if (type == "citystate") {
        const addressComponents = searchedAddress
          .split(",")
          .map((item) => item.trim());
        const city = addressComponents[0];
        const state = addressComponents[1];
        const country = addressComponents[2];
        const queryParams = `?city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const toggleStatus = `&isExclusiveProperty=${toggleChecked}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/citystate${queryParams}${toggleStatus}${queryString}`;
        navigate(routeLink);
      } else if (type == "address") {
        const addressComponents = searchedAddress
          .split(",")
          .map((item) => item.trim());
        const address = addressComponents[0];
        const [city, state, country] = addressComponents.slice(-3);
        const queryParams = `?address=${address}&city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const toggleStatus = `&isExclusiveProperty=${toggleChecked}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/address${queryParams}${toggleStatus}${queryString}`;
        navigate(routeLink);
      } else if (type == "viewport") {
        const queryParams = `?lat=${mapCenterForViewport.lat}&lng=${mapCenterForViewport.lng}&zoom=${zoom}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const toggleStatus = `&isExclusiveProperty=${toggleChecked}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/viewport${queryParams}${toggleStatus}${queryString}`;
        navigate(routeLink);
        console.log(routeLink);
      } else {
        const queryParams = `?lat=${mapCenter.lat}&lng=${mapCenter.lng}&zoom=${zoom}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const routeLink = `/landing/tenant/${tenant_name}/map-search${queryParams}&isExclusiveProperty=${toggleChecked}${queryString}`;
        navigate(routeLink);
      }

      window.location.reload();
    } else {
      if (type == "zipcode") {
        getHomesForSaleByZipcode(searchedAddress);
        const zipcode = searchedAddress.match(zipcodeRegex)?.[0];
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const queryParams = `?zipcode=${zipcode}&searched_address_format=${searchedAddress}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/zipcode${queryParams}${queryString}`;
        navigate(routeLink);
      } else if (type == "citystate") {
        getHomesForSaleByCitystate(searchedAddress);
        const addressComponents = searchedAddress
          .split(",")
          .map((item) => item.trim());
        const city = addressComponents[0];
        const state = addressComponents[1];
        const country = addressComponents[2];
        const queryParams = `?city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const routeLink = `/landing/tenant/${tenant_name}/map-search/citystate${queryParams}${queryString}`;
        navigate(routeLink);
      } else if (type == "address") {
        getHomesForSaleByAddress(searchedAddress);
        const addressComponents = searchedAddress
          .split(",")
          .map((item) => item.trim());
        const address = addressComponents[0];
        const [city, state, country] = addressComponents.slice(-3);
        const queryParams = `?address=${address}&city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const routeLink = `/landing/tenant/${tenant_name}/map-search/address${queryParams}${queryString}`;
        navigate(routeLink);
      } else if (type == "viewport") {
        const queryParams = `?lat=${mapCenterForViewport.lat}&lng=${mapCenterForViewport.lng}&zoom=${zoom}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const routeLink = `/landing/tenant/${tenant_name}/map-search/viewport${queryParams}${queryString}`;
        navigate(routeLink);
        console.log(routeLink);
      } else {
        getHomesForSaleByViewport(mapCenter);
        const queryParams = `?lat=${mapCenter.lat}&lng=${mapCenter.lng}&zoom=${zoom}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");

        const routeLink = `/landing/tenant/${tenant_name}/map-search${queryParams}${queryString}`;
        navigate(routeLink);
      }

      window.location.reload();
    }

    setShowFilters(false);
  };

  const handlePropertyTypeChange = (event) => {
    const { value, checked } = event.target;

    if (value === "All") {
      // If "All" is checked, set propertyTypeValues to all option values except the empty one ('')
      const allOptions = options.filter((option) => option.value !== "");
      const allOptionValues = allOptions.map((option) => option.value);
      setPropertyTypeValues(checked ? allOptionValues : []);
    } else {
      // If any other option is checked, add the value to the array
      // If any other option is unchecked, remove the value from the array
      setPropertyTypeValues((prevValues) =>
        checked
          ? [...prevValues, value]
          : prevValues.filter((val) => val !== value)
      );

      // If "All" is checked and any other option is unchecked, uncheck "All"
      if (value !== "All" && propertyTypeValues.includes("")) {
        setPropertyTypeValues((prevValues) =>
          prevValues.filter((val) => val !== "")
        );
      }

      // If any other option is checked and "All" is checked, uncheck "All"
      if (value === "All" && checked) {
        setPropertyTypeValues((prevValues) =>
          prevValues.filter((val) => val !== "")
        );
      }
    }
  };

  const handleCheckboxChange = (checkboxName) => {
    if (checkboxName === "excludeShortSales") {
      setExcludeShortSales(!excludeShortSales);
    } else if (checkboxName === "fixerUpper") {
      setFixerUpper(!fixerUpper);
    } else if (checkboxName === "openHouses") {
      setOpenHouses(!openHouses);
    } else if (checkboxName === "priceReduction") {
      setPriceReduction(!priceReduction);
    } else if (checkboxName === "isGarage") {
      setisGarage(!isGarage);
    } else if (checkboxName === "isAirCondition") {
      setisAirCondition(!isAirCondition);
    } else if (checkboxName === "isBasement") {
      setisBasement(!isBasement);
    } else if (checkboxName === "isHeating") {
      setisHeating(!isHeating);
    } else if (checkboxName === "isParking") {
      setisParking(!isParking);
    } else if (checkboxName === "isSwimming") {
      setisSwimming(!isSwimming);
    } else if (checkboxName === "greenHomes") {
      setgreenHomes(!greenHomes);
    } else if (checkboxName === "isLaundry") {
      setisLaundry(!isLaundry);
    }
  };

  const handleResetFilters = () => {
    setMinValue("");
    setMaxValue("");
    setBathFilter("");
    setBedFilter("");
    setAreaMinValue("");
    setAreaMaxValue("");
    setPropertyTypeValues("");
    setMaxHOA("");
    setminYearBuilt("");
    setminParking("");
    setminStories("");
    setmaxLotArea("");
    setminLotArea("");
    setminDays("");
    setmaxDays("");
    setmaxCost("");
    setminCost("");
    setpropertyStatus("");
    setlistingBy("");
    setExcludeShortSales(false);
    setFixerUpper(false);
    setOpenHouses(false);
    setPriceReduction(false);
    setisGarage(false);
    setisAirCondition(false);
    setisBasement(false);
    setisHeating(false);
    setisParking(false);
    setisSwimming(false);
    setgreenHomes(false);
    setisLaundry(false);
  };

  const toggleFilterVisibility = () => {
    setFilterVisibility(!isFilterVisible);
  };

  const toggleFilterAreaVisibility = () => {
    setFilterAreaVisibility(!isFilterAreaVisible);
  };

  const toggleFilterBedVisibility = () => {
    setFilterBedVisibility(!isFilterBedVisible);
  };

  const toggleFilterPropertyTypeVisibility = () => {
    setFilterPropertyTypeVisibility(!isFilterPropertyTypeVisible);
  };

  const toggleMoreFilterVisibility = () => {
    setMoreFilterVisibility(!isMoreFilterVisible);
  };

  const filteredOrderLimits = [
    { value: "50000", label: "50K" },
    { value: "75000", label: "75K" },
    { value: "100000", label: "100K" },
    { value: "125000", label: "125K" },
    { value: "150000", label: "150K" },
    { value: "175000", label: "175K" },
    { value: "200000", label: "200K" },
    { value: "225000", label: "225K" },
    { value: "250000", label: "250K" },
    { value: "275000", label: "275K" },
    { value: "300000", label: "300K" },
    { value: "325000", label: "325K" },
    { value: "350000", label: "350K" },
    { value: "375000", label: "375K" },
    { value: "400000", label: "400K" },
    { value: "425000", label: "425K" },
    { value: "450000", label: "450K" },
    { value: "475000", label: "475K" },
    { value: "500000", label: "500K" },
    { value: "525000", label: "525K" },
    { value: "550000", label: "550K" },
    { value: "575000", label: "575K" },
    { value: "600000", label: "600K" },
    { value: "625000", label: "625K" },
    { value: "650000", label: "650K" },
    { value: "675000", label: "675K" },
    { value: "700000", label: "700K" },
    { value: "725000", label: "725K" },
    { value: "750000", label: "750K" },
    { value: "775000", label: "775K" },
    { value: "800000", label: "800K" },
    { value: "825000", label: "825K" },
    { value: "850000", label: "850K" },
    { value: "875000", label: "875K" },
    { value: "900000", label: "900K" },
    { value: "925000", label: "925K" },
    { value: "950000", label: "950K" },
    { value: "975000", label: "975K" },
    { value: "1000000", label: "1M" },
    { value: "1250000", label: "1.25M" },
    { value: "1500000", label: "1.50M" },
    { value: "1750000", label: "1.75M" },
    { value: "2000000", label: "2M" },
    { value: "2250000", label: "2.25M" },
    { value: "2500000", label: "2.50M" },
    { value: "2750000", label: "2.75M" },
    { value: "3000000", label: "3M" },
    { value: "3250000", label: "3.25M" },
    { value: "3500000", label: "3.50M" },
    { value: "3750000", label: "3.75M" },
    { value: "4000000", label: "4M" },
    { value: "4250000", label: "4.25M" },
    { value: "4500000", label: "4.50M" },
    { value: "4750000", label: "4.75M" },
    { value: "5000000", label: "5M" },
    { value: "6000000", label: "6M" },
    { value: "7000000", label: "7M" },
    { value: "8000000", label: "8M" },
    { value: "9000000", label: "9M" },
    { value: "10000000", label: "10M" },

    // Add more options as needed
  ];

  const areaOptions = [
    { value: "500", label: "500 Sq.Ft" },
    { value: "750", label: "750 Sq.Ft" },
    { value: "1000", label: "1000 Sq.Ft" },
    { value: "1250", label: "1250 Sq.Ft" },
    { value: "1500", label: "1500 Sq.Ft" },
    { value: "1750", label: "1750 Sq.Ft" },
    { value: "2000", label: "2000 Sq.Ft" },
    { value: "2250", label: "2250 Sq.Ft" },
    { value: "2500", label: "2500 Sq.Ft" },
    { value: "2750", label: "2750 Sq.Ft" },
    { value: "3000", label: "3000 Sq.Ft" },
  ];

  const LotareaOptions = [
    { value: "2000", label: "2000 Sq.Ft" },
    { value: "4000", label: "4000 Sq.Ft" },
    { value: "6000", label: "6000 Sq.Ft" },
    { value: "8000", label: "8000 Sq.Ft" },
    { value: "10890", label: "0.25 Acre" },
    { value: "21780", label: "0.5 Acre" },
    { value: "43560", label: "1 Acre" },
    { value: "87120", label: "3 Acre" },
    { value: "217800", label: "5 Acre" },

    // Add more options as needed
  ];

  const CostareaOptions = [
    { value: "100", label: "$100/Sq.Ft" },
    { value: "150", label: "$150/Sq.Ft" },
    { value: "200", label: "$200/Sq.Ft" },
    { value: "250", label: "$250/Sq.Ft" },
    { value: "300", label: "$300/Sq.Ft" },
    { value: "350", label: "$350/Sq.Ft" },
    { value: "400", label: "$400/Sq.Ft" },
    { value: "450", label: "$450/Sq.Ft" },
    { value: "500", label: "$500/Sq.Ft" },
    { value: "550", label: "$550/Sq.Ft" },
    { value: "600", label: "$600/Sq.Ft" },
    { value: "650", label: "$650/Sq.Ft" },
    { value: "700", label: "$700/Sq.Ft" },
    { value: "750", label: "$750/Sq.Ft" },
    { value: "800", label: "$800/Sq.Ft" },
    { value: "850", label: "$850/Sq.Ft" },
    { value: "900", label: "$900/Sq.Ft" },
    { value: "950", label: "$950/Sq.Ft" },
    { value: "1000", label: "$1000/Sq.Ft" },

    // Add more options as needed
  ];

  const maxHOALimits = [
    { value: "0", label: "No HOA" },
    { value: "25", label: "$25/mon" },
    { value: "50", label: "$50/mon" },
    { value: "75", label: "$75/mon" },
    { value: "100", label: "$100/mon" },
  ];

  const CountLimits = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];

  const DaysOptions = [
    { value: "3", label: "Less than 3 days" },
    { value: "7", label: "Less than 7 days" },
    { value: "14", label: "Less than 14 days" },
    { value: "30", label: "Less than 30 days" },
    { value: "90", label: "Less than 90 days" },
  ];

  const PropertyStatusOptions = [
    { value: "COMING_SOON", label: "COMING_SOON" },
    { value: "CONTRACT", label: "CONTRACT" },
    { value: "OFF_MARKET", label: "OFF_MARKET" },
    { value: "PENDING", label: "PENDING" },
    { value: "SALE", label: "SALE" },
    { value: "SOLD", label: "SOLD" },
    { value: "UNDER_CONSTRUCTION", label: "UNDER_CONSTRUCTION" },
    { value: "UNKNOWN", label: "UNKNOWN" },
    { value: "WITHDRAWN", label: "WITHDRAWN" },
  ];

  const ListingOptions = [
    { value: "SHOPPROP", label: "SHOPPROP" },
    { value: "ARMLS", label: "ARMLS" },
    { value: "BAREISMLS", label: "BAREISMLS" },
    { value: "CENTRALMLS", label: "CENTRALMLS" },
    { value: "CRMLS", label: "CRMLS" },
    { value: "MLSLISTINGS", label: "MLSLISTINGS" },
    { value: "NWMLS", label: "NWMLS" },
    { value: "ACTRISMLS", label: "ACTRISMLS" },
    { value: "NTREISMLS", label: "NTREISMLS" },
  ];

  const handleSaveFilters = () => {
    let filterPayload = {};
    var filterForm = {
      min_price: minValue,
      max_price: maxValue,
      min_bathroom: BathFilter,
      min_bedroom: bedFilter,
      max_constructed_finished_sqft_size: areaMaxValue,
      min_constructed_finished_sqft_size: areaMinValue,
      property_type: propertyTypeValues,
      max_hoa_cost_per_month: maxHOA,
      min_year_built: minYearBuilt,
      min_parking_count: minParking,
      min_stories_count: minStories,
      min_lot_size_in_sqft: minLotArea,
      max_lot_size_in_sqft: maxLotArea,
      max_days: maxDays,
      min_days: minDays,
      min_cost_per_sqft: minCost,
      max_cost_per_sqft: maxCost,
      listed_by: listingBy,
      property_status: propertyStatus,
      exclude_short_sales: excludeShortSales,
      fixer_upper: fixerUpper,
      open_houses: openHouses,
      price_reduction: priceReduction,
      closed_garage: isGarage,
      has_ac: isAirCondition,
      has_basement: isBasement,
      has_central_heating: isHeating,
      has_parking_space: isParking,
      has_pool: isSwimming,
      is_green_home: greenHomes,
      is_in_unit_laundry: isLaundry,
    };

    for (let key in filterForm) {
      if (filterForm[key] && filterForm[key].length > 0) {
        filterPayload[key] = filterForm[key];
      }
    }

    if (excludeShortSales) {
      filterPayload.exclude_short_sales = excludeShortSales;
    }

    if (fixerUpper) {
      filterPayload.fixer_upper = fixerUpper;
    }

    if (openHouses) {
      filterPayload.open_houses = openHouses;
    }

    if (priceReduction) {
      filterPayload.price_reduction = priceReduction;
    }

    if (isGarage) {
      filterPayload.closed_garage = isGarage;
    }

    if (isAirCondition) {
      filterPayload.has_ac = isAirCondition;
    }

    if (isBasement) {
      filterPayload.has_basement = isBasement;
    }

    if (isHeating) {
      filterPayload.has_central_heating = isHeating;
    }

    if (isParking) {
      filterPayload.has_parking_space = isParking;
    }

    if (isSwimming) {
      filterPayload.has_pool = isSwimming;
    }

    if (greenHomes) {
      filterPayload.is_green_home = greenHomes;
    }

    if (isLaundry) {
      filterPayload.is_in_unit_laundry = isLaundry;
    }

    const savedFiltersJSON = localStorage.getItem("savedFilters");
    const savedFilters = savedFiltersJSON ? JSON.parse(savedFiltersJSON) : [];

    Swal.fire({
      title: "Enter a label to save filters",
      input: "text",
      inputPlaceholder: "Label",
      showCancelButton: true,
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "Label is required!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const filterWithLabel = { ...filterPayload, label: result.value };
        savedFilters.push(filterWithLabel);

        localStorage.setItem("savedFilters", JSON.stringify(savedFilters));

        Swal.fire({
          title: "Filters saved successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
      }
    });
  };

  const handleShowSavedFilters = () => {
    // Retrieve saved filters from local storage
    const savedFiltersJSON = localStorage.getItem("savedFilters");
    if (savedFiltersJSON) {
      const parsedFilters = JSON.parse(savedFiltersJSON);
      setSavedFilters(parsedFilters);
    }

    // Toggle the visibility of the saved filters div
    setShowSavedFilters(!showSavedFilters);
  };

  const handleSavedFilterSelection = (index) => {
    const filterPayload = savedFilters[index];

    const path = window.location.href;

    const lastSegment = path.split("/").pop();
    const type = lastSegment.split("?")[0];
    const searchedAddress = queryParams.get("searched_address_format");
    const zipcodeRegex = /\d{5}/;

    if (toggleChecked) {
      if (type == "zipcode") {
        const zipcode = searchedAddress.match(zipcodeRegex)?.[0];
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const queryParams = `?zipcode=${zipcode}&searched_address_format=${searchedAddress}`;
        const toggleStatus = `&isExclusiveProperty=${toggleChecked}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/zipcode${queryParams}${toggleStatus}${queryString}`;
        navigate(routeLink);
        window.location.reload();
      } else if (type == "citystate") {
        const addressComponents = searchedAddress
          .split(",")
          .map((item) => item.trim());
        const city = addressComponents[0];
        const state = addressComponents[1];
        const country = addressComponents[2];
        const queryParams = `?city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const toggleStatus = `&isExclusiveProperty=${toggleChecked}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/citystate${queryParams}${toggleStatus}${queryString}`;
        navigate(routeLink);
        window.location.reload();
      } else if (type == "address") {
        const addressComponents = searchedAddress
          .split(",")
          .map((item) => item.trim());
        const address = addressComponents[0];
        const [city, state, country] = addressComponents.slice(-3);
        const queryParams = `?address=${address}&city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const toggleStatus = `&isExclusiveProperty=${toggleChecked}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/address${queryParams}${toggleStatus}${queryString}`;
        navigate(routeLink);
        window.location.reload();
      } else if (type == "viewport") {
        const queryParams = `?lat=${mapCenter.lat}&lng=${mapCenter.lng}&zoom=${zoom}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const routeLink = `/landing/tenant/${tenant_name}/map-search/viewport${queryParams}&isExclusiveProperty=${!toggleChecked}${queryString}`;
        navigate(routeLink);
        console.log(routeLink);
      } else {
        const queryParams = `?lat=${mapCenter.lat}&lng=${mapCenter.lng}&zoom=${zoom}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const routeLink = `/landing/tenant/${tenant_name}/map-search${queryParams}&isExclusiveProperty=${toggleChecked}${queryString}`;
        navigate(routeLink);
      }

      window.location.reload();
    } else {
      if (type == "zipcode") {
        const zipcode = searchedAddress.match(zipcodeRegex)?.[0];
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const queryParams = `?zipcode=${zipcode}&searched_address_format=${searchedAddress}`;
        const routeLink = `/landing/tenant/${tenant_name}/map-search/zipcode${queryParams}${queryString}`;
        navigate(routeLink);
        window.location.reload();
      } else if (type == "citystate") {
        const addressComponents = searchedAddress
          .split(",")
          .map((item) => item.trim());
        const city = addressComponents[0];
        const state = addressComponents[1];
        const country = addressComponents[2];
        const queryParams = `?city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const routeLink = `/landing/tenant/${tenant_name}/map-search/citystate${queryParams}${queryString}`;
        navigate(routeLink);
        window.location.reload();
      } else if (type == "address") {
        const addressComponents = searchedAddress
          .split(",")
          .map((item) => item.trim());
        const address = addressComponents[0];
        const [city, state, country] = addressComponents.slice(-3);
        const queryParams = `?address=${address}&city=${city}&state=${state}&country=${country}&searched_address_format=${searchedAddress}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const routeLink = `/landing/tenant/${tenant_name}/map-search/address${queryParams}${queryString}`;
        navigate(routeLink);
        window.location.reload();
      } else if (type == "viewport") {
        const queryParams = `?lat=${mapCenter.lat}&lng=${mapCenter.lng}&zoom=${zoom}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const routeLink = `/landing/tenant/${tenant_name}/map-search/viewport${queryParams}${queryString}`;
        navigate(routeLink);
        console.log(routeLink);
      } else {
        const queryParams = `?lat=${mapCenter.lat}&lng=${mapCenter.lng}&zoom=${zoom}`;
        let queryString = Object.keys(filterPayload)
          .map(
            (key) =>
              `&${encodeURIComponent(key)}=${encodeURIComponent(
                filterPayload[key]
              )}`
          )
          .join("");
        const routeLink = `/landing/tenant/${tenant_name}/map-search${queryParams}${queryString}`;
        navigate(routeLink);
      }

      window.location.reload();
    }
    setShowSavedFilters(false);
  };

  const handleInputChange = (event) => {
    // Update the minYearBuilt state with the input value
    setminYearBuilt(event.target.value);
  };

  const options = [
    { value: "All", label: "All" },
    { value: "CONDO", label: "Condo" },
    { value: "HOUSE", label: "House" },
    { value: "LAND", label: "Land" },
    { value: "COMM_BUSINESS", label: "Commercial Business" },
    { value: "OTHER", label: "Other" },
  ];

  return (
    <div>
      <div className="header ">
        <img className="logo-home" src={logo} alt="Logo" />
        <h1 className="heading">Find Homes</h1>
      </div>
      <div className="search-container">
        <div className="search-input-container">
          <PlacesAutocomplete
            value={searchAddress}
            onChange={(address) => setSearchAddress(address)}
            onSelect={(address) => search(address)}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="suggestions-container">
                <input
                  className="search-input"
                  {...getInputProps({
                    placeholder: "Search by City, Address,Zipcode",
                  })}
                />
                <div className="suggestion-box">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#F7F7F8" : "#fff",
                      padding: "8px",
                      cursor: "pointer",
                      width: "250px",
                      zIndex: 1000000,
                    };

                    const iconStyle = {
                      color: suggestion.active ? "#FF7575" : "inherit",
                      marginRight: "10px",
                    };

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion)}
                        style={style}
                        key={suggestion.placeId}
                      >
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          style={iconStyle}
                        />
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <button className="search-button" onClick={handleButtonClick}>
          <FontAwesomeIcon icon={buttonIcon} />
        </button>
      </div>
      <div className="buttons text-center">
        <button
          className={`btns btn-new-top text-center${
            showMap ? " disabled" : ""
          }`}
          onClick={handleClick}
          disabled={showMap}
        >
          <div className="icon-class icon-color">
            <FontAwesomeIcon icon={faArrowDownWideShort} />
          </div>
        </button>

        <button
          className="btns btn-new-top text-center mx-2"
          onClick={handleFilterClick}
        >
          <div className="icon-class icon-color">
            <FontAwesomeIcon icon={faFilter} />
          </div>
        </button>

        <button
          className="btns btn-new-top text-center"
          onClick={handleSaveFilters}
        >
          <div className="icon-class icon-color">
            <FontAwesomeIcon icon={faSave} />
          </div>
        </button>

        <button
          className="btns btn-new-top text-center mx-2"
          onClick={handleShowSavedFilters}
        >
          <div className="icon-class icon-color">
            <FontAwesomeIcon icon={faBookmark} />
          </div>
        </button>

        <label className="toggle-switch">
          <input
            type="checkbox"
            checked={toggleChecked}
            onChange={handleToggle}
          />
          <span className="toggle-slider" />
        </label>
      </div>

      {isLoading && <Loader />}
      <div className="buttons-four">
        {showOptions && (
          <div className="sort-by-options">
            <label className="sort-label">View Order By:</label>
            <select
              className="sort-select"
              value={sortBy}
              onChange={handleSortChange}
            >
              <option value="">-- Select Option --</option>
              {sortOrderLimits.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}

        {showSavedFilters && (
          <div className="text-center saved-filters-container">
            <h2 style={{ color: "#333d79" }}>Saved Filters</h2>
            {savedFilters.length > 0 ? (
              savedFilters.map((filter, index) => (
                <div className="saved-filters text-center" key={index}>
                  <div>
                    {filter.label && (
                      <p className="saved-filter-details">{filter.label}</p>
                    )}
                  </div>
                  <button
                    className="saved-filter-btn"
                    onClick={() => handleSavedFilterSelection(index)}
                  >
                    Apply
                  </button>
                </div>
              ))
            ) : (
              <div className="text-center">
                <p className="no-filter">No Saved filters found!</p>
              </div>
            )}
          </div>
        )}

        {showFilters && (
          <div className="filters">
            <div className="filter-div">
              <div
                className="dropdown-heading"
                onClick={toggleFilterVisibility}
              >
                <h3 style={{ lineHeight: "2rem", marginRight: "13rem" }}>
                  Price
                </h3>
                <FontAwesomeIcon icon={faPlus} className="arrow-icon" />
              </div>
              {isFilterVisible && (
                <div className="price-filter">
                  <div className="options-filters">
                    <select
                      className="select-options"
                      value={minValue}
                      onChange={(e) => setMinValue(e.target.value)}
                    >
                      <option value="">Min</option>
                      {filteredOrderLimits.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <select
                      className="select-options"
                      value={maxValue}
                      onChange={(e) => setMaxValue(e.target.value)}
                    >
                      <option value="">Max</option>
                      {filteredOrderLimits.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
            <div className="filter-div">
              <div
                className="dropdown-heading"
                onClick={toggleFilterAreaVisibility}
              >
                <h3 style={{ lineHeight: "2rem", marginRight: "13.3rem" }}>
                  Area
                </h3>
                <FontAwesomeIcon icon={faPlus} className="arrow-icon" />
              </div>
              {isFilterAreaVisible && (
                <div className="price-filter">
                  <div className="options-filters">
                    <select
                      className="select-options"
                      value={areaMinValue}
                      onChange={(e) => setAreaMinValue(e.target.value)}
                    >
                      <option value="">Min Sq.Ft</option>
                      {areaOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <select
                      className="select-options"
                      value={areaMaxValue}
                      onChange={(e) => setAreaMaxValue(e.target.value)}
                    >
                      <option value="">Max Sq.Ft</option>
                      {areaOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
            <div className="filter-div">
              <div
                className="dropdown-heading"
                onClick={toggleFilterBedVisibility}
              >
                <h3 style={{ lineHeight: "2rem", marginRight: "7.6rem" }}>
                  Beds & Baths
                </h3>
                <FontAwesomeIcon icon={faPlus} className="arrow-icon" />
              </div>
              {isFilterBedVisible && (
                <div className="price-filter">
                  <div className="options-filters">
                    <p>Filter By Number of Beds:</p>
                    <button
                      className={`filter-option ${
                        bedFilter === 1 ? "active" : ""
                      }`}
                      onClick={() => setBedFilter(1)}
                    >
                      1+
                    </button>
                    <button
                      className={`filter-option ${
                        bedFilter === 2 ? "active" : ""
                      }`}
                      onClick={() => setBedFilter(2)}
                    >
                      2+
                    </button>
                    <button
                      className={`filter-option ${
                        bedFilter === 3 ? "active" : ""
                      }`}
                      onClick={() => setBedFilter(3)}
                    >
                      3+
                    </button>
                    <button
                      className={`filter-option ${
                        bedFilter === 4 ? "active" : ""
                      }`}
                      onClick={() => setBedFilter(4)}
                    >
                      4+
                    </button>
                  </div>
                  <div className="options-filters">
                    <p>Filter By Number of Baths:</p>
                    <button
                      className={`filter-option ${
                        BathFilter === 1 ? "active" : ""
                      }`}
                      onClick={() => setBathFilter(1)}
                    >
                      1+
                    </button>
                    <button
                      className={`filter-option ${
                        BathFilter === 2 ? "active" : ""
                      }`}
                      onClick={() => setBathFilter(2)}
                    >
                      2+
                    </button>
                    <button
                      className={`filter-option ${
                        BathFilter === 3 ? "active" : ""
                      }`}
                      onClick={() => setBathFilter(3)}
                    >
                      3+
                    </button>
                    <button
                      className={`filter-option ${
                        BathFilter === 4 ? "active" : ""
                      }`}
                      onClick={() => setBathFilter(4)}
                    >
                      4+
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="filter-div">
              <div
                className="dropdown-heading"
                onClick={toggleFilterPropertyTypeVisibility}
              >
                <h3 style={{ lineHeight: "2rem", marginRight: "7.3rem" }}>
                  Property Type
                </h3>
                <FontAwesomeIcon icon={faPlus} className="arrow-icon" />
              </div>
              {isFilterPropertyTypeVisible && (
                <div className="property-type-filter">
                  <div className="options-filters">
                    {options.map((option) => (
                      <label className="checkbox-label" key={option.value}>
                        <input
                          type="checkbox"
                          className="checkbox-option me-3"
                          value={option.value}
                          checked={propertyTypeValues.includes(option.value)}
                          onChange={handlePropertyTypeChange}
                        />
                        {option.label}
                      </label>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="filter-div">
              <div
                className="dropdown-heading"
                onClick={toggleMoreFilterVisibility}
              >
                <h3 style={{ lineHeight: "2rem", marginRight: "8.5rem" }}>
                  More Filters
                </h3>
                <FontAwesomeIcon icon={faPlus} className="arrow-icon" />
              </div>
              {isMoreFilterVisible && (
                <div className="price-filter">
                  <select
                    className="select-options"
                    value={maxHOA}
                    onChange={(e) => setMaxHOA(e.target.value)}
                  >
                    <option value="">Max HOA Fees</option>
                    {maxHOALimits.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <div style={{ padding: "1rem" }}>
                    <p>Filter By Min Year Built:</p>
                    <input
                      type="text"
                      value={minYearBuilt}
                      onChange={handleInputChange}
                      placeholder="Enter Minimum Built Year"
                      className="custom-input"
                    />
                  </div>

                  <select
                    className="select-options"
                    value={minStories}
                    onChange={(e) => setminStories(e.target.value)}
                  >
                    <option value="">Min Stories</option>
                    {CountLimits.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <select
                    className="select-options"
                    value={minParking}
                    onChange={(e) => setminParking(e.target.value)}
                  >
                    <option value="">Min Parking Count</option>
                    {CountLimits.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <select
                    className="select-options"
                    value={propertyStatus}
                    onChange={(e) => setpropertyStatus(e.target.value)}
                  >
                    <option value="">Property Status</option>
                    {PropertyStatusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <select
                    className="select-options"
                    value={listingBy}
                    onChange={(e) => setlistingBy(e.target.value)}
                  >
                    <option value="">Listed By</option>
                    {ListingOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <div className="options-filters">
                    <p>Filter By Lot Size:</p>

                    <select
                      className="select-options"
                      value={minLotArea}
                      onChange={(e) => setminLotArea(e.target.value)}
                    >
                      <option value="">Min Lot Area</option>
                      {LotareaOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <select
                      className="select-options"
                      value={maxLotArea}
                      onChange={(e) => setmaxLotArea(e.target.value)}
                    >
                      <option value="">Max Lot Area</option>
                      {LotareaOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="options-filters">
                    <p>Filter By Time in Market:</p>
                    <select
                      className="select-options"
                      value={minDays}
                      onChange={(e) => setminDays(e.target.value)}
                    >
                      <option style={{ maxWidth: "fit-content" }} value="">
                        Min Days
                      </option>
                      {DaysOptions.map((option) => (
                        <option
                          style={{ maxWidth: "fit-content" }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <select
                      className="select-options"
                      value={maxDays}
                      onChange={(e) => setmaxDays(e.target.value)}
                    >
                      <option value="">Max Days</option>
                      {DaysOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="options-filters">
                    <p>Filter By Cost/Sq.Ft:</p>

                    <select
                      className="select-options"
                      value={minCost}
                      onChange={(e) => setminCost(e.target.value)}
                    >
                      <option value="">Min Cost/Sq.ft</option>
                      {CostareaOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <select
                      className="select-options"
                      value={maxCost}
                      onChange={(e) => setmaxCost(e.target.value)}
                    >
                      <option value="">Max Cost/Sq.ft</option>
                      {CostareaOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="options-filters">
                    <p>Filter By Options:</p>
                    <label className="checkbox-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        checked={excludeShortSales}
                        onChange={() =>
                          handleCheckboxChange("excludeShortSales")
                        }
                      />
                      Exclude Short Sales
                    </label>

                    <label className="checkbox-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        checked={fixerUpper}
                        onChange={() => handleCheckboxChange("fixerUpper")}
                      />
                      Fixer Upper
                    </label>

                    <label className="checkbox-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        checked={openHouses}
                        onChange={() => handleCheckboxChange("openHouses")}
                      />
                      Open Houses
                    </label>

                    <label className="checkbox-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        checked={priceReduction}
                        onChange={() => handleCheckboxChange("priceReduction")}
                      />
                      Price Reduction
                    </label>
                  </div>

                  <div className="options-filters">
                    <p>Filter By Features:</p>
                    <label className="checkbox-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        checked={isGarage}
                        onChange={() => handleCheckboxChange("isGarage")}
                      />
                      Garage
                    </label>

                    <label className="checkbox-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        checked={isAirCondition}
                        onChange={() => handleCheckboxChange("isAirCondition")}
                      />
                      Air Condition
                    </label>

                    <label className="checkbox-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        checked={isBasement}
                        onChange={() => handleCheckboxChange("isBasement")}
                      />
                      Basement
                    </label>

                    <label className="checkbox-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        checked={isHeating}
                        onChange={() => handleCheckboxChange("isHeating")}
                      />
                      Central Heating
                    </label>

                    <label className="checkbox-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        checked={isParking}
                        onChange={() => handleCheckboxChange("isParking")}
                      />
                      Parking Space
                    </label>

                    <label className="checkbox-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        checked={isSwimming}
                        onChange={() => handleCheckboxChange("isSwimming")}
                      />
                      Swimming Pool
                    </label>

                    <label className="checkbox-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        checked={greenHomes}
                        onChange={() => handleCheckboxChange("greenHomes")}
                      />
                      Green Homes Only
                    </label>

                    <label className="checkbox-label">
                      <input
                        className="me-3"
                        type="checkbox"
                        checked={isLaundry}
                        onChange={() => handleCheckboxChange("isLaundry")}
                      />
                      In-unit Laundry
                    </label>
                  </div>
                </div>
              )}
            </div>

            <div className="filter-buttons">
              <button
                className="apply-reset-button"
                onClick={handleApplyFilters}
              >
                Apply
              </button>
              <button
                className="apply-reset-button"
                onClick={handleResetFilters}
              >
                Reset
              </button>
            </div>
          </div>
        )}
      </div>
      {showMap && (
        <GoogleMap
          ref={mapRef}
          onLoad={(map) => {
            // Store the map instance in a ref or state variable
            mapRef.current = map;
          }}
          mapContainerStyle={{
            width: "100%",
            height: "76%",
            position: "absolute",
            display:
              isLoading || showFilters || showSavedFilters ? "none" : "block",
          }}
          zoom={zoom}
          center={mapCenter}
          // onDragEnd={handleMapMove}
        >
          {markers.length > 0 && (
            <MarkerClusterer
              options={{
                imagePath:
                  "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
              }}
            >
              {(clusterer) =>
                markers.map((marker, index) => (
                  <Marker
                    key={index}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    icon={{
                      url: markerIcon, // Custom marker image
                      scaledSize: new window.google.maps.Size(60, 60),
                    }}
                    clusterer={clusterer}
                    onClick={() => handleMarkerClick(marker)}
                  />
                ))
              }
            </MarkerClusterer>
          )}

          {selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
              onCloseClick={handleInfoWindowClose}
            >
              <Link
                to={`/landing/tenant/${tenant_name}/property/${selectedMarker.property_descriptor_mls}/${selectedMarker.property_descriptor}`}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(e.currentTarget.getAttribute("href"), "_blank");
                }}
                style={{ textDecoration: "none" }}
              >
                <div style={{ display: "flex" }}>
                  {selectedMarker?.imageUrl && (
                    <img
                      src={selectedMarker?.imageUrl}
                      alt="Property"
                      style={{ height: "100px", width: "80px" }}
                    />
                  )}
                  <div>
                    <p
                      style={{
                        margin: "10px",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      ${selectedMarker?.price}
                    </p>
                    <p style={{ margin: "10px" }}>
                      {selectedMarker?.area || "N/A"} sqft
                    </p>
                    <p style={{ margin: "10px" }}>
                      Beds:{selectedMarker?.bedrooms || "N/A"}
                    </p>
                    <p style={{ margin: "10px" }}>
                      Baths: {selectedMarker?.bathrooms || "N/A"}
                    </p>
                  </div>
                </div>
              </Link>
            </InfoWindow>
          )}
        </GoogleMap>
      )}

      {!showMap && (
        <div
          style={{
            display:
              isLoading || showFilters || showSavedFilters ? "none" : "block",
          }}
        >
          {properties.length === 0 ? (
            <div className="no-property-found text-center">
              <img
                src={ErrorImg}
                className="error-img"
                alt="No properties found"
              />
              <h2>Property Not Found !</h2>
              <p>
                We weren't able to find any properties with the applied filters
                or searched address, Please change your selections
              </p>
            </div>
          ) : (
            properties.map((property, index) => (
              <a
                key={index}
                href={`/#/landing/tenant/${tenant_name}/property/${property.property_descriptor_mls}/${property.property_descriptor}`}
                style={{
                  marginBottom: "20px",
                  background: "#333D79FF",
                  color: "white",
                  textDecoration: "none",
                  display: "block",
                }}
              >
                {property.imageUrl ? (
                  <img
                    src={property.imageUrl}
                    alt="Property"
                    style={{ height: "300px", width: "100%" }}
                  />
                ) : (
                  <img
                    src={NoImage}
                    alt="No images available"
                    style={{ height: "300px", width: "100%" }}
                  />
                )}

                <h2 style={{ margin: "10px" }}>
                  {property.address.google_address}
                </h2>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ margin: "10px", fontWeight: "bold" }}>
                    ${property.price}
                  </p>
                  <p style={{ margin: "10px" }}>
                    {property.area || "N/A"} sqft
                  </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ margin: "10px" }}>
                    Bedrooms: {property.bedrooms || "N/A"}
                  </p>
                  <p style={{ margin: "10px" }}>
                    Bathrooms: {property.bathrooms || "N/A"}
                  </p>
                </div>
              </a>
            ))
          )}
        </div>
      )}

      <Footer />
    </div>
  );
};

export default MapComponent;
